import React from "react";

import { pageLinks } from "../data/pageLinks";

import Image from "../components/v2/Image";
import Layout from "../components/v2/Layout";
import ContentBlock from "../components/v2/ContentBlock";
import Hero from "../components/v2/Hero";
import ImageGrid from "../components/v2/ImageGrid";
import PageSection from "../components/v2/PageSection";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

// import ToyotaTester from "../images/ToyotaTester.mp4";

import Checkerboard from "../components/v2/CheckerBoard";

const introText = "";

const TvcEndFrame = ({ location }) => {
  return (
    <Layout appLocation={location}>
      {/* <HeroVideo videoFile={ToyotaTester} /> */}
      <Hero text={introText} navindex="0" />
      <PageSection sectionId="tvc-endframe">
        <ContentBlock>
          <h2>TVC Endframe</h2>
          <div className="text">
            <p>
              To ensure strong, recognisable branding across all video and audio
              assets, it’s important to have a consistent end tag.
            </p>
            <p>
              We will always end on our staging platform on both broadcast and
              online assets. There is a end frame for videos over 30 seconds and
              a shorter version for videos under 30 seconds.
            </p>
            <p>
              The same applies to radio, whereby we need to end on a consistent
              and distinctive Toyota audio mnemonic.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" placeholder></Image>
          <Image width="half" placeholder></Image>
          <Image width="half" placeholder></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="lets-go-places-endframe">
        <ContentBlock>
          <h2>
            <em>Let’s Go Places</em> end frame
          </h2>
          <div className="text">
            <h3>End frame</h3>
            <p>
              The <em>Let’s Go Places</em> end frame is preferred. Please use
              the design and animation provided for your formatand media.
            </p>
            <h3>Art direction</h3>
            <p>
              The last shot of the video should be art directed with the end
              frame transition in mind - wide shots are preferred if possible.
              Avoid close ups on faces or important objects in the center of
              frame, as we do not cover them with the end frame transition.{" "}
            </p>
            <h3>Editing notes</h3>
            <p>
              Make sure there are no cuts or important action at the same time
              as the transition. Any actions relevant to the story should
              conclude before the <em>Let’s Go Places</em> logo transition.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" placeholder></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="brand-campaign-endframe">
        <ContentBlock>
          <h2>Brand Campaign end frame</h2>
          <div className="text">
            <p>
              Below are some additional guidelines for brand campaigns that have
              their own lock up or end line.
            </p>
          </div>
        </ContentBlock>
        <Checkerboard
          placeholder
          title="Legibility"
          text="Where there is a campaign lock up or line, please ensure that it is clearly legible."
        />
        <Checkerboard
          placeholder
          alt={true}
          title="Editing notes"
          text="Ensure the lock up or line fades out before the <em>Let’s Go Places</em> logo transitions on. There should always be at least one clean frame between the graphics and the logo animation."
        />
        <Checkerboard
          placeholder
          title="CTA"
          text="A simple call to action can be included in the final end frame below the <em>Let’s Go Places</em> logo. Please refer to the example and guidelines."
        />
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Photography"
        previousUrl={pageLinks.photography}
        nextText="Next"
        nextHeader="Hey Toyota"
        nextUrl={pageLinks["hey-toyota"]}
      />
    </Layout>
  );
};

export default TvcEndFrame;